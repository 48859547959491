
















































































import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { download } from "@/utils";
import { dispatch, Captcha } from "@/store";

export default Vue.extend({
  data() {
    return {
      moment,
      sn: "",
      captcha: {} as Captcha,
      loading: false,
      name: "",
      whereVisible: false,
      alertVisible: false,
      result: null
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    products(): any {
      return {
        cyberboard: {
          title: this.$t("cyberboard.name"),
          name: "CYBERBOARD",
          where: this.$t("verify.where.cyberboard"),
          bg: this.oss.verify_cyberboard_bg,
          sn: this.oss.verify_cyberboard_sn,
          userManual: ""
        },
        cyberblade: {
          title: this.$t("cyberblade.name"),
          name: "CYBERBLADE",
          where: "",
          bg: this.oss.verify_cyberblade_bg,
          sn: "",
          userManual: ""
        },
        "am-hatsu": {
          title: this.$t("AMHatsu.name"),
          name: "AM HATSU",
          where: this.$t("verify.where.AMHatsu"),
          bg: this.oss.verify_am_hatsu_bg,
          sn: this.oss.verify_am_hatsu_sn,
          userManual: ""
        },
        "am-afa": {
          title: this.$t("AMAfa.name"),
          name: "AM AFA",
          where: "",
          bg: this.oss.verify_am_afa_bg,
          sn: this.oss.verify_am_afa_sn,
          userManual: ""
        },
        "am-65-less": {
          title: this.$t("AM65Less.name"),
          name: "AM 65 Less",
          where: "",
          bg: this.oss.verify_am_65_less_bg,
          sn: this.oss.verify_am_65_less_sn,
          userManual: ""
        },
        cybermat: {
          title: this.$t("cybermat.name"),
          name: "CYBERMAT",
          where: this.$t("verify.where.cybermat"),
          bg: this.oss.verify_cybermat_bg,
          sn: this.oss.verify_cybermat_sn,
          userManual: ""
        },
        "black-diamond-75": {
          title: "BLACK DIAMOND 75",
          name: "BLACK DIAMOND 75",
          where: this.$t("verify.where.cyberboard"),
          bg: this.oss.black_diamond_75_bg,
          sn: this.oss.black_diamond_75_sn,
          userManual: ""
        }
      };
    },
    product(): any {
      if (this.name && this.products[this.name]) {
        return this.products[this.name];
      }
      return this.products.cyberboard;
    }
  },
  methods: {
    verifyInit() {
      this.captcha = {} as Captcha;
      const NoCaptcha = (window as any).NoCaptcha;
      const nc_token = [
        "FFFF0N00000000009B12",
        new Date().getTime(),
        Math.random()
      ].join(":");
      const nc = NoCaptcha.init({
        renderTo: "#nc",
        appkey: "FFFF0N00000000009B12",
        scene: "nc_other_h5",
        token: nc_token,
        trans: { key1: "code200" },
        elementID: ["usernameID"],
        is_Opt: 0,
        language: this.isCN ? "cn" : "en",
        timeout: 10000,
        retryTimes: 5,
        errorTimes: 5,
        inline: false,
        apimap: {
          // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
          // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
        },
        bannerHidden: false,
        initHidden: false,
        callback: (data: any) => {
          this.captcha = {
            session_id: data.csessionid,
            sign: data.sig,
            token: nc_token,
            scene: "nc_other_h5"
          };
        }
      });
      NoCaptcha.setEnabled(true);
      nc.reset(); //请务必确保这里调用一次reset()方法
    },
    onDownload() {
      download(this.product.userManual);
    },
    onSNInput() {
      this.sn = this.sn.trim().substring(0, 36);
      this.result = null;
    },
    onSearch() {
      this.loading = true;
      dispatch
        .verifyCheck({
          captcha: this.captcha,
          sn_code: this.sn
        })
        .then(data => {
          // if (data.times > 1) {
          //   this.alertVisible = true;
          // }
          this.loading = false;
          this.result = data;
          this.verifyInit();
        })
        .catch(() => {
          this.loading = false;
          this.result = null;
          this.verifyInit();
        });
    }
  },
  watch: {
    ["$route.params.name"](name) {
      if (name) {
        this.name = name;
      }
    }
  },
  mounted() {
    this.name = this.$route.params.name || "cyberboard";
    if (!this.user.isSignIn) {
      dispatch.userSignIn();
    } else {
      if (this.product) {
        this.verifyInit();
      } else {
        this.$router.push(this.$paths.home);
      }
    }
  }
});
